import gql from 'graphql-tag';
import {Query} from "@deity/falcon-ecommerce-uikit";

export const GET_MINI_CART = gql`
  query MiniCart {
    cart {
      itemsQty
      quoteCurrency
      error
      couponCode
      totals {
        code
        title
        value
      }
      items {
        itemId
        sku
        qty
        name
        price
        link
        urlKey
        thumbnailUrl
        shortDescription
        manufacturer
        regularPriceInclTax
        rowTotalInclTax
        itemOptions {
            label
            value
        }        
      }
    }
  }
`;


export class MiniCartQuery extends Query {
    static defaultProps = {
        query: GET_MINI_CART
    };
}
