import React from 'react';
import { Box } from '@deity/falcon-ui';
import { SignIn, SignUp, ForgotPassword } from '@deity/falcon-ecommerce-uikit';
// MiniCart from falcon ui-ki

import {SIDEBAR_CONTENT_TYPES, SIDEBAR_CONTEXT_KEY} from './SidebarQuery';
import {MiniCart} from '../../MiniCart/MiniCart'
import {MiniCartQuery} from "../../../../queries/MiniCartQuery";
import {CartQuery} from "../../../../queries/CartQuery";
export default (data) => {
    const { contentType, error, contextKey, contextItemId } = data;
  // if there is no content type provided it means that sidebar contents should be rendered as hidden
  // if unrecognized content type is provided add warning about it
  if (contentType && !SIDEBAR_CONTENT_TYPES[contentType]) {
    const message = `Unrecognized sidebar content type: ${contentType}`;
    if (process.env.NODE_ENV !== 'production') {
      throw new Error(message);
    }

    console.error(message);
  }

  // using hidden attribute will cause react to consider rendering it as low priority
  // (in version > 16.6) - https://github.com/oliviertassinari/react-swipeable-views/issues/453#issuecomment-417939459
  return (
    <React.Fragment>
      <ContentBox current={contentType} contentType={SIDEBAR_CONTENT_TYPES.cart} css={{ height: '100%'}}>
        <CartQuery onCompleted={(data) => {
          try {
            const brevoData = {
              id: data.cart.id,
              data: {
                itemsQty: data.cart.itemsQty,
                products: data.cart.items.map((item) => {
                  return {
                    url: item.urlKey,
                    name: item.name,
                    brand: item.manufacturer,
                    shortDescription: item.shortDescription,
                    thumbnailUrl: item.thumbnailUrl,
                    optionLabel: (item.itemOptions.length > 0 ? item.itemOptions[0].value : '')
                  }
                })
              }
            }
  
            window.sendinblue.track('cart_updated', {}, brevoData);
          } catch (e) {
            console.error(e)
          }
        }}>
          {data => <MiniCart {...data} error={error} contextKey={contextKey} contextItemId={contextItemId}/>}
        </CartQuery>
      </ContentBox>
    </React.Fragment>
  );
};

const ContentBox = ({ current, contentType, children, ...rest }) => {
  const hidden = current !== contentType;
  const key = contentType + hidden;

  return (
    <Box key={key} hidden={hidden} {...rest}>
      {children}
    </Box>
  );
};
