import React, {useState} from 'react';
import {
    Box,
} from '@deity/falcon-ui';
// import { RemoveCartItemMutation, UpdateCartItemMutation } from '@deity/falcon-ecommerce-uikit';
import {CloseSidebarMutation} from '@deity/falcon-ecommerce-uikit';
import {toGridTemplate} from '@deity/falcon-ecommerce-uikit';
import {LocaleProvider} from '@deity/falcon-ecommerce-uikit';
import {CartItemRow, formatPrice, MiniCart as MiniCartUI} from "benlux-ecommerce-ui";
import './MiniCart.css'
import {RemoveCartItemMutation, UpdateCartItemMutation} from "../../../mutation/CartMutation";
import {OpenSidebarMutation} from "../components/Sidebar/SidebarMutations";
import {MenuQuery} from "../../../queries/MenuQuery";


export const MiniCartProductArea = {
    empty: '.',
    thumb: 'thumb',
    price: 'price',
    productName: 'productName',
    modify: 'modify',
    remove: 'remove'
};

const miniCartProductTheme = {
    miniCartProduct: {
        display: 'grid',
        gridGap: 'xs',
        // prettier-ignore
        gridTemplate: toGridTemplate([
            ['30px', '1fr', '2fr'],
            [MiniCartProductArea.empty, MiniCartProductArea.thumb, MiniCartProductArea.productName],
            [MiniCartProductArea.remove, MiniCartProductArea.thumb, MiniCartProductArea.price, '1fr'],
            [MiniCartProductArea.empty, MiniCartProductArea.thumb, MiniCartProductArea.modify]
        ])
    }
};


const miniCartLayout = {
    miniCartLayout: {
        // display: 'grid',
        // gridRowGap: 'md',
        // css: {
        //     width: '100%',
        // },
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      css: {
        height: '100%',
      },
      // gridTemplateRows: 'auto auto 100%'
    }
};

const MiniCartProduct = ({product}) => (
    <div>
        <UpdateCartItemMutation>
            {(updateCartItem, {loading: updatingQty, error}) =>
                <RemoveCartItemMutation>
                    {removeCartItem => (
                        <CartItemRow
                            item={product}
                            onRemove={() =>
                                removeCartItem({
                                    variables: {input: {itemId: product.itemId}},
                                    optimisticResponse: {
                                        removeCartItem: {
                                            __typename: 'RemoveCartItemResponse',
                                            itemId: product.itemId
                                        }
                                    }
                                })
                            }
                            error={error}
                            onQtyUpdate={(qty) => updateCartItem({
                                variables: {
                                    input: {
                                        itemId: product.itemId,
                                        sku: product.sku,
                                        qty: qty
                                    }
                                }
                            })}
                            updatingQty={updatingQty}
                        />
                    )}
                </RemoveCartItemMutation>
            }
        </UpdateCartItemMutation>
    </div>
);

const MiniCartProducts = ({products, contextItemId, totals}) => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const productPrice = products.map(item => item.rowTotalInclTax);
    const totalItems = products.map(item => item.qty);
    const totalItemsLength = totalItems.reduce(reducer, 0);

    let priceTotal = productPrice.reduce(reducer, 0);
    const discount = totals.find((t) => t.code === 'discount');
    let discountTitle = 'Remise';
    if (discount) {
        try {
            discountTitle = /Discount \((.*)\)/.exec(discount.title)[1];
        } catch (e) {}
    }

    const filterItemId = products.map(product => product.itemId)
        .filter(p => p.itemId === contextItemId)

    return (
        <>
            {contextItemId ? (
                <div style={{flex: 1}}>
                    {products.map((product, index) => (
                        <div key={index}>
                        {product.itemId === contextItemId &&
                            <MiniCartProduct product={product}/>
                         }
                        </div>
                    ))}
                </div>
            ) : (
                <div style={{height: '100%', flex: 1}}>
                <div style={{padding: 0, margin: 0, listStyle: "none", overflowX: "auto"}} className={"scrollItem"}>
                    {products.map((product, index) => (
                        <div key={index}>
                            <MiniCartProduct product={product}/>
                        </div>
                    ))}
                </div>
                  {(priceTotal ? (
                    <>
                        <div className={"totalPrice"}>
                            <span>Sous-total ({totalItemsLength} article{totalItemsLength > 1 ? 's' : ''})</span>
                            <span>{formatPrice(priceTotal)} €</span>
                        </div>
                        {discount && (
                            <>
                                <div className={"totalPrice"} style={{marginTop: '12px'}}>
                                    <span style={{fontSize: '14px', color: '#ef394f', fontWeight: '600'}}>{discountTitle}</span>
                                    <span style={{fontSize: '14px', color: '#ef394f'}}>{formatPrice(discount.value)} €</span>
                                </div>
                                <div className={"totalPrice"}>
                                    <span>Total (hors frais de livraison)</span>
                                    <span>{formatPrice(priceTotal + discount.value)} €</span>
                                </div>
                            </>
                        )}
                    </>
                    ) : (
                      <div>Votre panier est vide !</div>
                    )
                  )}
                 </div>
            )}
        </>
    )
};

export const MiniCart = ({cart: {quoteCurrency, items, totals}, error, contextKey, contextItemId}) => {
    return (
      <MenuQuery>{menu => (
        <CloseSidebarMutation>
          {closeSidebar => (
            <LocaleProvider currency={quoteCurrency}>
              <Box defaultTheme={miniCartLayout}>
                <OpenSidebarMutation>
                  {openSidebar =>
                    <MiniCartUI
                      error={error}
                      items={items}
                      contextKey={contextKey}
                      removeContextKey={() => openSidebar({variables: {contentType: 'cart'}})}
                      contextItemId={contextItemId}
                      closeSidebar={() => closeSidebar()}
                      menu={menu}
                      miniCartProducts={
                        <MiniCartProducts
                          products={items}
                          totals={totals}
                          contextItemId={contextItemId}
                        />
                      }
                    />
                  }
                </OpenSidebarMutation>
              </Box>
            </LocaleProvider>
          )}
        </CloseSidebarMutation>
      )}
      </MenuQuery>
    )
}
